.spin {
  left: 50%;
  top: 50%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  path {
    fill: var(--header-logo-gradient);;
  }
}

ion-spinner {
  display: flex;
  justify-content: center;
}
