.copyright-ctn {
  height: 35px;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: row;
  background-color: var(--headerbg) !important;
  // border-top: 1px solid #454646;
  margin-left: 20px;
  margin-right: 20px;

  .brand {
    font-weight: bold;
  }

  span,
  div {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 56px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: var(--text);
    opacity: .6;

    .copyright-link {
      color: var(--text);
      @media screen and (min-width: 920px) {
        padding-left: 6px;
      }
    }
    // color: rgba(255, 255, 255, 0.6) !important;
  }
}


@media screen and (max-width: 720px) {
  .rules-text {
    margin-bottom: 105px;
  }

  .d-block {
    display: block !important;
    text-align: center !important;
  }
  .copyright-ctn {
    justify-content: center;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 46px;
    padding-top: 12px;
  }

  .copyright-ctn,
  .copyright-ctn-light {

    span,
    div {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
    }
  }
}

.payment-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--ion-background);
  border-top: 1px solid rgba(106, 115, 148, 0.35);
  .w-30,
  .w-70 {
    width: 75%;
    &.w-30 {
      width: 75% !important;
    }
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (max-width: 720px) {
      width: 90%;
    }
  }
  .payment-ctn2,
  .payment-ctn1,
  .payment-ctn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &.payment-ctn1 {
      grid-template-columns: repeat(2, 1fr);
    }
    &.payment-ctn2 {
      grid-template-columns: repeat(1, 1fr);
    }
    @media screen and (max-width: 720px) {
      grid-template-columns: repeat(1, 1fr);
      &.payment-ctn1 {
        grid-template-columns: repeat(2, 1fr);
      }
      &.payment-ctn2 {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .payment-card {
      background: var(--header-btn-background);
      display: flex;
      align-items: center !important;
      justify-content: center;
      padding: 6px;
      border-radius: 8px;
      border: 1px solid #CF8D2E;
      margin-top: 10px;
      margin-bottom: 10px;
      min-width: 230px;
      margin-left: 10px;
      margin-right: 10px;
      .grid {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--text);
        font-weight: 400;
        text-decoration: none;
        margin-left: 4px;
        margin-right: 4px;
        .icon-ctn {
          height: 32px;
          width: 32px;
        }
        .whatsup-col{
          border-radius: 50%;
        }
        @media screen and (max-width: 720px) {
          cursor: pointer;
          color: var(--text);
          font-weight: 400;
          text-decoration: none;
          margin-left: 2px;
          margin-right: 2px;
          font-size: 12px;
          .icon-ctn {
            height: 28px;
            width: 28px;
          }
          .whatsup-col{
            border-radius: 50%;
          }
        }
      }
    }
  }
}