.link {
  margin-top: 0;
  text-align: left;
  display: block;
  color: var(--text);

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;

  @media screen and (max-width: 720px) {
  font-size: 12px;
  }

  &.pb-50 {
    padding-bottom: 50px;

    @media screen and (max-width: 720px) {
      padding-bottom: 40px;
    }
  }

  .register-link {
    color: #CF8D2E;
    cursor: pointer;
    text-align: center;
    position: relative;
    z-index: 999;
  }
}

.login-form-ctn {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  text-align: center;

  .login-logo-background {
    path {
      fill: var(--header-logo-gradient);
    }
  }

  .login-demologin-btns {
    display: flex;
    justify-content: space-between;
    .login-form-btn {
      margin-top: 40px !important;
      margin-bottom: 20px;
      width: 48%;
      height: 50px;
      font-size: 13px;
      border-radius: 11px;
      // line-height: 19px;
      background: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast) !important;
      font-weight: bold;

      .MuiButton-label {
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        text-transform: capitalize;
      }
    }
  }

  .login-demologin-btns {
    display: flex;
    justify-content: space-around;
    .login-form-btn-without-demologin{
      margin-top: 40px !important;
      margin-bottom: 20px;
      width: 100%;
      height: 50px;
      font-size: 13px;
      border-radius: 11px;
      // line-height: 19px;
      background: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast) !important;
      font-weight: bold;

      .MuiButton-label {
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        text-transform: capitalize;
      }
    }
  }

  .sign-in-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 54px;
    letter-spacing: -0.02em;
    color: var(--text);

    @media screen and (max-width: 720px) {
      font-size: 20px;
    }
  }

  .heading {
    z-index: 1111;
    position: relative;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text);
    background: #CF8D2E;
    width: fit-content;
    padding: 6px 7px;
    border-radius: 0 10px 0 10px;
    margin: 14px auto 16px auto;

    @media screen and (max-width: 720px) {
      margin: 24px auto 30px auto;
    }

    .title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 10.3506px;
      line-height: 13px;

      /* identical to box height */
      display: flex;
      align-items: center;
      text-align: center;

      color: #FFFFFF;

      // font-weight: 600;
      // font-size: 24px;
      // line-height: 16px;
      // text-transform: capitalize;
      // color: rgba(255, 255, 255, 0.6);
      @media screen and (max-width: 720px) {

        font-weight: 700;
        font-size: 10.3506px;
        line-height: 13px;
      }
    }
  }

  .title-border-line {
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, #CF8D2E 52.35%, #000000 100.9%);
    position: absolute;
    top: 96px;

    @media screen and (max-width: 720px) {
      top: 106px;
    }
  }

  .login-col {
    width: 100%;
    margin-top: 15px;
  }

  .MuiOutlinedInput-root {
    background-color: var(--ion-background) !important;
    // border-bottom: 3px solid var(--headerbg) !important;
    border: 0px;
    color: var(--text) !important;
  }

  .MuiFormControl-root {
    display: flex;
    width: 100%;
  }

  .MuiInputBase-root .MuiFormControl-root,
  .MuiOutlinedInput-root,
  input {
    // background-color: var(--ion-background) !important;
    color: var(--text) !important;
    border-radius: 0px;
  }

  ::placeholder {
    font-size: 16px;
    color: var(--text) !important;
    padding: 2px;
    font-family: var(--font-family);
    opacity: 1;
  }

  .login-form-btn {
    margin-top: 1.5rem;
    height: 55px;
    width: 100%;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
    background-color: var(--active-link);
    color: var(--text-common-dark) !important;
    border-radius: 6px;

    @media screen and (max-width: 720px) {
      margin-top: 0;
      height: 44px;
    }

    .MuiButton-label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #FFFFFF;

      @media screen and (max-width: 720px) {
        font-size: 18px !important;
      }
    }

    .MuiIconButton-label {
      svg {
        display: none;
        fill: var(--text-common-dark) !important;
        color: var(--text-common-dark) !important;
      }
    }

    .MuiButton-label {
      color: #fff !important;
    }
  }

  .link {
    margin-top: auto;
    margin-top: -0.5rem;
    font-size: 14px;
    text-align: left;

    .register-link {
      color: #6D767F;
      cursor: pointer;
    }
  }

  .login-err-msg {
    color: var(--text-primary) !important;
  }
}

.login-ctn {
  display: flex;
  flex-direction: row;

  .left-ctn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;

    @media screen and (max-width: 720px) {
      display: none;
    }
  }

  .right-ctn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 720px) {
      width: 100%
    }

    .tab-list {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .Mui-selected {
        border-bottom: 2px solid var(--active-link) !important;

        .MuiTab-wrapper {
          font-size: 24px;
          font-weight: 400;
          color: var(--active-link) !important;
        }
      }

      .MuiTab-root {
        min-width: 50%;
        border-bottom: 2px solid var(--text);

        .MuiTab-wrapper {
          font-size: 24px;
          font-weight: 400;
          color: var(--text);
        }
      }
    }

    .tab-ctn {
      width: 100%;
      padding: 0px;
      // padding-left: 2%;
      // padding-right: 2%;
      margin: 0px;
      display: flex;
      flex-direction: column;

      .header-title {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        font-size: 18px;
      }

      .header-ctn {
        display: flex;
        justify-content: center;
        margin-top: 14px;

        .dot {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--headerbg);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-left: 10px;
          margin-right: 10px;

          .icon {
            height: 25px;
          }
        }
      }
    }
  }
}

@media only screen and (max-device-width: 440px) {
    .login-form-ctn{
      .login-demologin-btns {
        display: flex;
        flex-direction: column;
        .login-form-btn {
          width: 100%;
          height: 35px;
          margin-top: 20px !important;
          margin-bottom: 10px !important;
          border-radius: 5px;
          .MuiButton-label {
            font-size: 16px;

          }
        }
      }
    }
}